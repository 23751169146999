<div class="barcode-container">
    <button *ngIf='shouldRenderFakeButton' class='fake-button' cdkFocusInitial>Focus Capture</button>
    <button mat-icon-button type="button" (click)="cancel()" class='close-button' aria-label='Close Button'>
        <mat-icon class="close-icon" svgIcon="close"></mat-icon>
    </button>
    <!-- note that we hide the live video element using 'display:none', that way only the canvas is rendered -->
    <video id="dash-gis-barcode-vid" webkit-playsinline playsinline autoplay style='display:none'></video>
    <canvas id="dash-gis-barcode-image"></canvas>
    <canvas id="dash-gis-barcode-vf"></canvas>
</div>
