import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { LoggerService } from '../../../../modules/shared/services/logger.service';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthState } from '../../state/auth.state';
import {
    BeginConfirmAccount,
    CheckConfirmationKey,
    CheckConfirmationKeyFailed,
    CheckConfirmationKeySuccess,
    ConfirmAccountFailed,
    ConfirmAccountSuccess, LoginSuccessful
} from '../../state/auth.actions';
import { map, take, takeUntil, tap } from 'rxjs/operators';
import { Navigate } from '@ngxs/router-plugin';

@Component({
    selector: 'dash-gis-confirm-account',
    templateUrl: './confirm-account.component.html',
    styleUrls: [ './confirm-account.component.scss' ]
})
export class ConfirmAccountComponent implements OnInit {
    valid$ = new BehaviorSubject<boolean>(false);
    beenChecked$ = new BehaviorSubject<boolean>(false);
    confirmationKey$: Observable<string>;

    private dispose$ = new Subject<void>();

    constructor(
        private logger: LoggerService,
        private store: Store,
        private actions$: Actions,
        private route: ActivatedRoute,
        private snack: MatSnackBar
    ) {}

    ngOnInit(): void {
        const authenticated$ = this.store.select(AuthState.validated);
        this.confirmationKey$ = this.route.queryParamMap.pipe(map((params) => params.get('key')));

        combineLatest([ authenticated$, this.beenChecked$, this.confirmationKey$ ]).pipe(
            takeUntil(this.dispose$)
        ).subscribe(([ authenticated, beenChecked, confirmationKey ]) => {
            if (!authenticated && !beenChecked) {
                this.store.dispatch(new CheckConfirmationKey(confirmationKey));
            }
        });

        this.actions$.pipe(
            ofActionSuccessful(CheckConfirmationKeySuccess),
            takeUntil(this.dispose$)
        ).subscribe(() => {
            this.valid$.next(true);
            this.beenChecked$.next(true);
        });

        this.actions$.pipe(
            ofActionSuccessful(CheckConfirmationKeyFailed),
            takeUntil(this.dispose$)
        ).subscribe(() => {
            this.valid$.next(false);
            this.beenChecked$.next(true);
        });

        const successMessage = 'Your password has been successfully set. You will now be logged in.';
        this.actions$.pipe(
            ofActionSuccessful(ConfirmAccountSuccess),
        ).subscribe(() => this.snack.open(successMessage, 'Got it', { duration: 5000 }));

        const failureMessage = 'There was an error setting your new password: ';
        this.actions$.pipe(
            ofActionSuccessful(ConfirmAccountFailed)
        ).subscribe((action) => this.snack.open(failureMessage + action.message, 'OK'));
    }

    ngOnDestroy(): void {
        this.dispose$.next();
    }

    confirmAccount(event) {
        combineLatest([ this.beenChecked$, this.valid$, this.confirmationKey$ ]).pipe(
            take(1)
        ).subscribe(([ beenChecked, valid, confirmationKey ]) => {
            if (beenChecked && valid) {
                this.store.dispatch(new BeginConfirmAccount({
                    newPassword: event.newPassword,
                    newPasswordConfirm: event.newPasswordConfirm,
                    confirmationKey
                }));
            }
        });
    }

    cancelReset(): void {
        this.store.dispatch(new Navigate([ '/' ], { key: null, valid: null }, { queryParamsHandling: 'merge' }));
    }
}
