import { Component, OnDestroy, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { MapConfigState } from '../state/map-config.state';
import { MapContextMenuItemConfiguration } from '../types/widget-configuration';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { AuthService } from '../../../core/security/services/auth.service';

@Component({
    selector: 'dash-gis-map-context-menu',
    templateUrl: './map-context-menu.component.html',
    styleUrls: [ './map-context-menu.component.scss' ]
})
export class MapContextMenuComponent implements OnInit, OnDestroy {

    visibleMenuItems$: Observable<MapContextMenuItemConfiguration[]>;

    @Select(MapConfigState.loaded) private mapConfigLoaded$: Observable<boolean>;
    @Select(MapConfigState.contextMenuItems) private menuItems$: Observable<MapContextMenuItemConfiguration[]>;

    private dispose$ = new Subject<void>();

    constructor(
        private authService: AuthService
    ) { }

    ngOnInit(): void {

        this.visibleMenuItems$ = this.mapConfigLoaded$.pipe(
            distinctUntilChanged(),
            filter(loaded => loaded),
            switchMap(() => this.menuItems$),
            map(widgets => widgets.filter(w => this.authService.isAuthorized(w.roles || [])))
        );
    }

    ngOnDestroy() {
        this.dispose$.next();
    }
}
