import { Component, Inject, OnInit } from '@angular/core';
import { LoggerService } from '../../../../shared/services/logger.service';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { RestService } from '../../../../shared/services/rest.service';
import { fromArray } from 'rxjs/internal/observable/fromArray';
import { map, mergeMap, take, tap } from 'rxjs/operators';
import { VideoUploadUrl } from '../../../../../types';
import { FileUploadDialogData } from '../../../../reports/interface/reportControls/upload/state/upload.model';

@Component({
    selector: 'dash-gis-attach-dialog',
    templateUrl: './attach-dialog.component.html',
    styleUrls: ['./attach-dialog.component.scss'],
})
export class AttachDialogComponent implements OnInit {
    filesToUpload: File[] = [];

    public constructor(
        private logger: LoggerService,
        private rest: RestService,
        private dialogRef: DialogRef<AttachDialogComponent>,
        @Inject(DIALOG_DATA) public data: FileUploadDialogData,
    ) {}

    ngOnInit(): void {
        // check once for camera existing
        // this.deviceService.hasVideoInput$.subscribe(hasVideo => this.hasVideoInput$ = hasVideo);
    }

    public cancel() {
        this.dialogRef.close(null);
    }

    /**
     * Add new elements to list of files that will be uploaded, excluding duplicates identified
     * by name and size
     * @param files
     */
    public setFileToUpload(files: File[]) {
        files.forEach((newFile) => {
            if (this.matchExistingFile(newFile)) {
                this.filesToUpload.push(newFile);
            }
        });
    }

    doUpload() {
        fromArray(this.filesToUpload).pipe(
            map(f => f.name),
            mergeMap((filename) => this.rest.apiGet<VideoUploadUrl>('video/uploadUrl', {path: filename})),
            mergeMap((response) => {
                const file = this.filesToUpload.find(f => f.name === response.filename);
                const options = {
                    responseType: 'text',
                    headers: { 'x-ms-blob-type': 'BlockBlob' },
                };
                return this.rest.put(response.url, null, file, options);
            }),
            take(this.filesToUpload.length),
        ).subscribe();
    }

    public removeFile(index: number) {
        this.filesToUpload.splice(index, 1);
    }

    public get canUploadFile() {
        return this.filesToUpload.length > 0;
    }

    private matchExistingFile(file: File): boolean {
        const matchTest = (f) => f.name === file.name && f.size === file.size;
        return -1 === this.filesToUpload.findIndex(matchTest);
    }
}
