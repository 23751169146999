import { Component, Input, OnDestroy, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BarcodeScannerDialogComponent } from './barcode-scanner-dialog.component';
import { Subject } from 'rxjs';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { takeUntil } from 'rxjs/operators';
import { LoggerService } from '../../../../shared/services/logger.service';
import { AbstractControl } from '@angular/forms';

@Component({
    selector: 'dash-gis-barcode-scanner-button',
    templateUrl: './barcode-scanner-button.component.html',
    styleUrls: ['./barcode-scanner-button.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class BarcodeScannerButtonComponent implements OnDestroy {
    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input()
    target: AbstractControl;

    private destroySubject = new Subject<void>();

    constructor(private logger: LoggerService, private dialog: MatDialog, private media: MediaObserver) {}

    ngOnDestroy() {
        this.destroySubject.next();
    }

    scan() {
        const dialogRef = this.dialog.open(BarcodeScannerDialogComponent, {
            hasBackdrop: true,
            disableClose: false,
            panelClass: 'barcode-panel',
            maxWidth: '100vw',
            maxHeight: '100vh',
        });

        this.media
            .asObservable()
            .pipe(takeUntil(this.destroySubject))
            .subscribe((changes: MediaChange[]) => {
                let matched = false;
                changes
                    .filter((change) => change.matches)
                    .forEach((change) => {
                        if (change.mqAlias === 'handset') {
                            dialogRef.updateSize('100%', '100%');
                            matched = true;
                        }
                    });
                if (!matched) {
                    dialogRef.updateSize('', '');
                }
            });

        dialogRef.afterClosed().subscribe((value) => {
            if (value !== false && this.target) {
                this.target.setValue(value);
                this.target.updateValueAndValidity({ onlySelf: true, emitEvent: true });
            }
        });
    }
}
