<div class='reset-password-page' fxFill fxLayout='column' fxLayoutAlign='center center'>
    <mat-card class='reset-password-card mat-elevation-z2'>
        <mat-toolbar color='primary' fxLayout='row' fxLayoutAlign='start center'>
            <h2 class='mat-toolbar-tools'>Password Reset</h2>
        </mat-toolbar>
        <mat-card-content cdkTrapFocus>
            <ng-container *ngIf='!(beenChecked$ | async); then checkingTemplate; else checkedTemplate'></ng-container>
        </mat-card-content>
    </mat-card>
</div>

<ng-template #checkingTemplate>
    <mat-progress-spinner mode='indeterminate'></mat-progress-spinner>
</ng-template>

<ng-template #checkedTemplate>
    <ng-container *ngIf='valid$ | async; then validTemplate else invalidTemplate'></ng-container>
</ng-template>

<ng-template #invalidTemplate>
    <div>The link you provided is invalid or expired. Please check it or try again from the login page.</div>
    <button (click)='cancelReset()'>Back to Login</button>
</ng-template>

<ng-template #validTemplate>
    <dash-gis-reset-password-form (formSubmit)='resetPassword($event)'
                            (formCancel)='cancelReset()'></dash-gis-reset-password-form>
</ng-template>


