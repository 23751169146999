<div class='confirm-password-page' fxFill fxLayout='column' fxLayoutAlign='center center'>
    <mat-card class='confirm-password-card mat-elevation-z2'>
        <mat-toolbar color='primary' fxLayout='row' fxLayoutAlign='start center'>
            <h2 class='mat-toolbar-tools'>Confirm Account</h2>
        </mat-toolbar>
        <mat-card-content cdkTrapFocus>
            <ng-container *ngIf='!(beenChecked$ | async); then checkingTemplate; else checkedTemplate'></ng-container>
        </mat-card-content>
    </mat-card>
</div>

<ng-template #checkingTemplate>
    <mat-progress-spinner mode='indeterminate'></mat-progress-spinner>
</ng-template>

<ng-template #checkedTemplate>
    <ng-container *ngIf='(valid$ | async); then validTemplate; else invalidTemplate'></ng-container>
</ng-template>

<ng-template #validTemplate>
    <div fxLayout='column' fxLayoutAlign='start start' fxLayoutGap='24px'>
        <div>Thank you for confirming your email address. In order to access your account, please set a password.</div>
        <dash-gis-reset-password-form [submitText]='"Set Password"'
                                (formSubmit)='confirmAccount($event)'
                                (formCancel)='cancelReset()'></dash-gis-reset-password-form>
    </div>
</ng-template>

<ng-template #invalidTemplate>
    <div>
        The link you provided to confirm your account is either invalid or expired. Please contact support
        to request a new link.
    </div>
    <div fxLayout='row' fxLayoutAlign='end center'>
        <button mat-button (click)='cancelReset()'>OK</button>
    </div>
</ng-template>
