import { AfterViewInit, Component, ElementRef, Input, OnInit } from '@angular/core';
import { LoggerService } from '../../modules/shared/services/logger.service';
import { QrCodeService } from '../../modules/shared/services/qr-code.service';

@Component({
    selector: 'dash-gis-qr-code',
    templateUrl: './qr-code.component.html',
    styleUrls: ['./qr-code.component.scss'],
})
export class QrCodeComponent implements OnInit, AfterViewInit {
    @Input() qrValue: string;
    @Input() qrWidth = 30;

    constructor(protected logger: LoggerService, protected qrService: QrCodeService, protected el: ElementRef) {}

    ngOnInit(): void {}

    ngAfterViewInit() {
        this.qrService
            .generateQrCode$(this.qrValue, this.qrWidth)
            .subscribe((svgQrCode: string) => (this.el.nativeElement.innerHTML = svgQrCode));
    }
}
