import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DataFormDefinition, DataFormField, DataFormValueModel } from '../state/dataForm.model';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, mergeMap, take, takeUntil, tap } from 'rxjs/operators';
import { Select, Store } from '@ngxs/store';
import { DataFormState } from '../state/dataForm.state';
import { ApiSuccessResponse } from '../../../types';
import { RefreshMap } from '../../map/state/active-map.actions';
import { RefreshFilterFields } from '../state/report.actions';
import { LoggerService } from '../../shared/services/logger.service';
import { DataFormService } from '../services/dataform.service';
import { MapService } from '../../map/services/map.service';
import { MapConfigService } from '../../map/services/mapConfig.service';
import { UpdateFormFieldValue } from '../state/dataForm.actions';
import { isEqual } from 'lodash-es';
import { AuthState } from '../../../core/security/state/auth.state';

@Component({
    selector: 'dash-gis-data-form-content',
    templateUrl: './data-form-content.component.html',
    styleUrls: ['./data-form-content.component.scss'],
})
export class DataFormContentComponent implements OnInit, OnDestroy {
    @Input() formName: string;

    @Output() setDisplayName = new EventEmitter<string>(true);
    @Output() isValid = new EventEmitter<boolean>();
    @Output() shouldClose = new EventEmitter<boolean>();

    dataForm: UntypedFormGroup = null;
    initializedSubject = new BehaviorSubject<boolean>(false);
    initialized$ = this.initializedSubject.asObservable();

    @Select(DataFormState.definition) definition$: Observable<(formName: string) => DataFormDefinition>;
    @Select(DataFormState.keys) keys$: Observable<(formName: string) => any[]>;
    @Select(DataFormState.value) value$: Observable<(formName: string) => DataFormValueModel>;
    @Select(DataFormState.field) field$: Observable<(formName: string, fieldName: string) => DataFormField>;

    formDefinition$: Observable<DataFormDefinition>;
    formValue$: Observable<DataFormValueModel>;

    saveError$: Subject<ApiSuccessResponse> = new Subject<ApiSuccessResponse>();

    private dispose$ = new Subject<void>();

    /*
     Associated fields are those that change options based on the value of another control.

     The key in these objects is the name of the field that controls the values -- in the
     model, this is the field 'parameter'

     As an example, 'field1' is a normal dropdown. 'field2' has a parameter value of 'field1'. Therefore,
     this.associatedFields['field1'] would return the name 'field2', and this.associatedOptionSubject['field1']
     would return a subject used to get the options for field2.

     In the method 'onFieldChanges', when field1 is changed, the subject used to update associated dropdown options
     is obtained by accessing this.associatedOptionSubjects with the field name of the changed field (NOT THE
     ASSOCIATED FIELD)
     */
    private associatedFields: {
        [key: string]: string;
    } = {};
    private associatedOptionSubjects = {};

    constructor(
        public logger: LoggerService,
        public dataFormService: DataFormService,
        public mapService: MapService,
        public mapConfig: MapConfigService,
        public fb: UntypedFormBuilder,
        private store: Store
    ) {
        this.formDefinition$ = this.definition$.pipe(
            map((fn) => fn(this.formName)),
            distinctUntilChanged(isEqual)
        );
        this.formValue$ = this.value$.pipe(
            map((fn) => fn(this.formName)),
            distinctUntilChanged(isEqual)
        );
    }

    ngOnInit(): void {
        this.formDefinition$
            .pipe(
                tap((definition) => {
                    if (!definition) {
                        this.isValid.emit(false);
                    }
                }),
                filter((definition: DataFormDefinition) => definition !== null),
                tap((definition: DataFormDefinition) => this.setDisplayName.emit(definition.displayName)),
                map((definition: DataFormDefinition) => {
                    const formGroup = {};
                    for (const field of definition.fields) {
                        const validators = this.mapValidators(field);

                        let shouldDisable = false;
                        let sourceControl: UntypedFormControl = null;
                        if (!!field.parameter && formGroup[field.parameter]) {
                            sourceControl = formGroup[field.parameter];
                            sourceControl.updateValueAndValidity({
                                onlySelf: true,
                                emitEvent: false,
                            });
                            if (!sourceControl.valid) {
                                shouldDisable = true;
                            }
                        }

                        const newControl = new UntypedFormControl(
                            {
                                value: field.currentValue || this.getFieldDefault(field),
                                disabled: shouldDisable,
                            },
                            { validators }
                        );
                        formGroup[field.fieldName] = newControl;

                        if (field.parameter) {
                            // register field association
                            this.associatedFields[field.parameter] = field.fieldName;
                            this.associatedOptionSubjects[field.parameter] = new BehaviorSubject<any[]>([]);
                            // set initial value
                            if (sourceControl) {
                                this.updateTargetField(
                                    field.fieldName,
                                    newControl,
                                    field.parameter,
                                    sourceControl.value
                                );
                            }
                        }

                        this.onFieldChanges.bind(this, field.fieldName)(formGroup[field.fieldName]);

                        // prevent user from having to select
                        if (field.dataType === 'user' && !field.currentValue) {
                            formGroup[field.fieldName].markAsDirty();
                            formGroup[field.fieldName].updateValueAndValidity({emitEvent: true});
                        }
                    }
                    this.dataForm = new UntypedFormGroup(formGroup);
                    this.dataForm.statusChanges
                        .pipe(distinctUntilChanged())
                        .subscribe((status) => this.isValid.emit(status === 'VALID'));
                    this.initializedSubject.next(true);
                }),
                take(1)
            )
            .subscribe();
    }

    ngOnDestroy(): void {
        this.isValid.emit(false);
        this.setDisplayName.emit('');
        this.shouldClose.emit(true);
        this.dispose$.next();
    }

    saveData() {
        this.formValue$
            .pipe(
                mergeMap((formModel: DataFormValueModel) => this.dataFormService.saveData(formModel)),
                mergeMap((response: ApiSuccessResponse) => {
                    if (response.success) {
                        return of(response.success).pipe(
                            tap(() => this.store.dispatch(new RefreshMap())),
                            tap(() => this.store.dispatch(new RefreshFilterFields()))
                        );
                    } else {
                        this.saveError$.next(response);
                        return of(response.success);
                    }
                }),
                takeUntil(this.dispose$)
            )
            .subscribe((success) => {
                if (success) {
                    this.shouldClose.emit(success);
                }
            });
    }

    // helper function for multi-select controls
    isSelected(optionValue, fieldValue) {
        return Array.isArray(fieldValue) ? fieldValue.indexOf(optionValue) !== -1 : fieldValue === optionValue;
    }

    getField$(fieldName: string): Observable<DataFormField> {
        return this.field$.pipe(map((fn) => fn(this.formName, fieldName)));
    }

    isDateInvalid(fieldName: string): boolean {
        const field = this.dataForm.get(fieldName);
        return field.hasError('matDatepickerParse');
    }

    isDateRequiredAndMissing(fieldName: string): boolean {
        const field = this.dataForm.get(fieldName);
        return field.hasError('required') && !field.value;
    }

    getDropDownOptions$(field: DataFormField): Observable<any[]> {
        if (field.parameter) {
            const subject = this.associatedOptionSubjects[field.parameter];
            return subject ? subject.asObservable() : of([]);
        }
    }

    trackByDbNameFn(_: number, item: DataFormField) {
        return item.dbFieldName;
    }

    private mapValidators(field: DataFormField) {
        const validators = [];
        if (field.required) {
            validators.push(Validators.required);
        }
        if (field.validationExpression) {
            validators.push(Validators.pattern(field.validationExpression));
        }
        return validators;
    }

    private onFieldChanges(fieldName: string, control: AbstractControl) {
        control.valueChanges.subscribe((fieldValue) => {
            // check associated fields
            const associatedField = this.associatedFields[fieldName];
            if (associatedField) {
                const associatedControl = this.dataForm.get(associatedField);
                this.updateTargetField(associatedField, associatedControl, fieldName, fieldValue);
            }

            const payload = {
                formName: this.formName,
                data: {
                    name: fieldName,
                    value: fieldValue,
                    dirty: control.dirty,
                },
            };
            this.store.dispatch(new UpdateFormFieldValue(payload));
        });
    }

    private updateTargetField(
        targetFieldName: string,
        targetControl: AbstractControl,
        sourceFieldName: string,
        sourceFieldValue: any
    ) {
        const subject = this.associatedOptionSubjects[sourceFieldName];
        if (sourceFieldValue !== '') {
            this.dataFormService
                .getDropDownOptions$(this.formName, targetFieldName, sourceFieldValue)
                .subscribe((options) => {
                    subject.next(options);
                });
            targetControl.enable({ onlySelf: true });
        } else {
            subject.next([]);
            targetControl.patchValue(null);
            targetControl.disable({ onlySelf: true });
        }
    }

    private getFieldDefault(field: DataFormField): string|string[] {
        switch (true) {
            case field.uiType === 'dropdown_multi':
                return [];
            case field.dataType === 'user':
                return this.store.selectSnapshot(AuthState.user)?.username || '';
            default:
                return '';
        }
    }
}
