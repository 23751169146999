import { AfterContentInit, Component, OnDestroy, OnInit } from '@angular/core';
import { getDefaultScanner, ImageScanner, scanImageData, Symbol } from 'zbar.wasm';
import { LoggerService } from '../../../../shared/services/logger.service';
import { DeviceService } from '../../../../shared/services/device.service';
import {
    catchError,
    concatMap,
    concatMapTo,
    delay,
    filter,
    map,
    switchMap,
    take,
    takeUntil,
    tap
} from 'rxjs/operators';
import { defer, interval, Subject } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'dash-gis-barcode-scanner-dialog',
    templateUrl: './barcode-scanner-dialog.component.html',
    styleUrls: ['./barcode-scanner-dialog.component.scss'],
})
export class BarcodeScannerDialogComponent implements OnInit, AfterContentInit, OnDestroy {
    shouldRenderFakeButton = true;
    video: HTMLVideoElement;
    canvas: HTMLCanvasElement;
    viewfinder: HTMLCanvasElement;
    decodedValue = '';
    scanArea: [number, number, number, number];

    cancelScan$ = new Subject<void>();

    constructor(
        private logger: LoggerService,
        private deviceService: DeviceService,
        private dialogRef: MatDialogRef<BarcodeScannerDialogComponent>
    ) {}

    ngOnInit(): void {
        this.dialogRef.beforeClosed().subscribe(() => this.disableCapture());
    }

    ngAfterContentInit() {
        this.scan();

        setTimeout(() => {
            this.shouldRenderFakeButton = false;
        }, 5000);
    }

    ngOnDestroy() {
        this.cancelScan$.next();
    }

    scan() {
        this.enableCapture()
            .pipe(
                concatMapTo(defer(() => getDefaultScanner())),
                switchMap((scanner) => this.scanImage(scanner)),
                map((result: Symbol[]) => {
                    if (result.length > 0) {
                        return result[0].decode();
                    }
                }),
                tap(() => {
                    // animate flash on viewfinder
                    const vfCtx = this.viewfinder.getContext('2d');
                    for (let b = 0, g = 255; b <= 255 && g >= 0; b++, g--) {
                        vfCtx.clearRect(0, 0, this.viewfinder.width, this.viewfinder.height);
                        this.drawViewFinder(vfCtx, `rgb(0, ${g}, ${b}`);
                    }
                }),
                tap((value) => {
                    setTimeout(() => (this.decodedValue = value), 0);
                }),
                delay(3000),
                take(1)
            )
            .subscribe((value: string) => {
                this.cancelScan$.next();
                this.dialogRef.close(value);
            });
    }

    enableCapture() {
        this.initializeScanUI();
        const captureOptions = {
            video: {
                // width: Math.max(this.canvas.width, this.video.width),
                // height: Math.max(this.canvas.height, this.video.height),
                facingMode: 'environment',
            },
        };
        return this.deviceService.enableCamera(this.video, captureOptions).pipe(
            filter(Boolean),
            concatMapTo(defer(() => this.video.play())),
            tap(() => {
                // have to wait until video is active to get dimensions
                this.canvas.width = this.viewfinder.width = this.video.videoWidth;
                this.canvas.height = this.viewfinder.height = this.video.videoHeight;
            }),
            tap(() => {
                const vfCtx = this.viewfinder.getContext('2d');
                this.drawViewFinder(vfCtx, 'rgb(0, 255, 0)');
            }),
            catchError((err, caught$) => {
                this.logger.error(err);
                return caught$;
            })
        );
    }

    disableCapture() {
        this.deviceService.disableCamera(this.video);
    }

    cancel() {
        this.dialogRef.close(false);
    }

    private scanImage(scanner: ImageScanner) {
        const ctx = this.canvas.getContext('2d');
        return interval(80).pipe(
            map(() => {
                ctx.drawImage(this.video, 0, 0, this.canvas.width, this.canvas.height);
                return ctx.getImageData(...this.scanArea);
            }),
            concatMap((imageData) => defer(() => scanImageData(imageData, scanner))),
            filter((result) => result.length > 0),
            takeUntil(this.cancelScan$)
        );
    }

    private initializeScanUI() {
        const videoElement = document.getElementById('dash-gis-barcode-vid');
        if (videoElement) {
            this.video = videoElement as HTMLVideoElement;
        }
        const canvasElement = document.getElementById('dash-gis-barcode-image');
        if (canvasElement) {
            this.canvas = canvasElement as HTMLCanvasElement;
        }
        const viewFinderElement = document.getElementById('dash-gis-barcode-vf');
        if (viewFinderElement) {
            this.viewfinder = viewFinderElement as HTMLCanvasElement;
        }
    }

    private drawViewFinder(ctx: CanvasRenderingContext2D, color: string) {
        ctx.strokeStyle = color;
        const ctxWidth = ctx.canvas.width;
        const ctxHeight = ctx.canvas.height;
        // viewfinder width = 80% of smallest dimension
        const dim = Math.floor(Math.min(ctxWidth, ctxHeight) * 0.5);
        const minX = (ctxWidth - dim) / 2.0;
        const maxX = minX + dim;
        const minY = (ctxHeight - dim) / 2.0;
        const maxY = minY + dim;

        // line length = 10% of dim
        const armLength = dim * 0.1;
        ctx.beginPath();
        ctx.moveTo(maxX - armLength, minY);
        ctx.lineTo(maxX, minY);
        ctx.lineTo(maxX, minY + armLength);
        ctx.moveTo(maxX, maxY - armLength);
        ctx.lineTo(maxX, maxY);
        ctx.lineTo(maxX - armLength, maxY);
        ctx.moveTo(minX + armLength, maxY);
        ctx.lineTo(minX, maxY);
        ctx.lineTo(minX, maxY - armLength);
        ctx.moveTo(minX, minY + armLength);
        ctx.lineTo(minX, minY);
        ctx.lineTo(minX + armLength, minY);
        ctx.lineWidth = 5;
        ctx.stroke();

        this.scanArea = [minX, minY, Math.abs(maxX - minX), Math.abs(maxY - minY)];
    }
}
