import { Component, Input, OnInit, Output } from '@angular/core';
import { MapCommandMenuItem } from '../../types';

@Component({
    selector: 'dash-gis-sub-menu',
    templateUrl: './map-context-sub-menu.component.html',
    styleUrls: [ './map-context-sub-menu.component.scss' ]
})
export class MapContextSubMenuComponent implements OnInit {

    @Input() menuItems: MapCommandMenuItem[];

    constructor() { }

    ngOnInit(): void {
    }
}
