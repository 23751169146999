import { Injector, NgModule } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { SharedModule } from '../../modules/shared/shared.module';
import { QrCodeComponent } from './qr-code.component';

@NgModule({
    imports: [SharedModule],
    declarations: [QrCodeComponent],
})
export class QrCodeModule {
    constructor(private injector: Injector) {
        const el = createCustomElement(QrCodeComponent, {
            injector: this.injector,
        });
        customElements.define('dash-gis-qr-code', el);
    }
}
