import {
    ChangeDetectorRef,
    Component,
    ContentChild,
    ElementRef,
    EventEmitter,
    Inject,
    Input,
    NgZone,
    OnInit,
    Output,
    PLATFORM_ID,
    TemplateRef,
} from '@angular/core';
import { LegendPosition, LineChartComponent, ScaleType } from '@swimlane/ngx-charts';
import { curveLinear } from 'd3-shape';

@Component({
    selector: 'dash-gis-custom-line-chart',
    templateUrl: './custom-line-chart.component.html',
    styleUrls: ['./custom-line-chart.component.scss'],
})
export class CustomLineChartComponent extends LineChartComponent implements OnInit {
    @Input() legend: boolean;
    @Input() legendTitle: string = 'Legend';
    @Input() legendPosition: LegendPosition = LegendPosition.Right;
    @Input() legendColumns: number;
    @Input() xAxis: boolean;
    @Input() yAxis: boolean;
    @Input() showXAxisLabel: boolean;
    @Input() showYAxisLabel: boolean;
    @Input() xAxisLabel: string;
    @Input() yAxisLabel: string;
    @Input() autoScale: boolean;
    @Input() timeline: boolean;
    @Input() gradient: boolean;
    @Input() showGridLines: boolean = true;
    @Input() curve: any = curveLinear;
    @Input() activeEntries: any[] = [];
    @Input() schemeType: ScaleType;
    @Input() rangeFillOpacity: number;
    @Input() trimXAxisTicks: boolean = true;
    @Input() trimYAxisTicks: boolean = true;
    @Input() rotateXAxisTicks: boolean = true;
    @Input() maxXAxisTickLength: number = 16;
    @Input() maxYAxisTickLength: number = 16;
    @Input() xAxisTickFormatting: any;
    @Input() yAxisTickFormatting: any;
    @Input() xAxisTicks: any[];
    @Input() yAxisTicks: any[];
    @Input() roundDomains: boolean = false;
    @Input() tooltipDisabled: boolean = false;
    @Input() showRefLines: boolean = false;
    @Input() referenceLines: any;
    @Input() showRefLabels: boolean = true;
    @Input() xScaleMin: number;
    @Input() xScaleMax: number;
    @Input() yScaleMin: number;
    @Input() yScaleMax: number;

    @Output() activate: EventEmitter<any> = new EventEmitter();
    @Output() deactivate: EventEmitter<any> = new EventEmitter();

    @ContentChild('tooltipTemplate') tooltipTemplate: TemplateRef<any>;
    @ContentChild('seriesTooltipTemplate') seriesTooltipTemplate: TemplateRef<any>;

    constructor(
        protected chartElement: ElementRef,
        protected zone: NgZone,
        protected cd: ChangeDetectorRef,
        @Inject(PLATFORM_ID) public platformId: any
    ) {
        super(chartElement, zone, cd, platformId);
    }

    ngOnInit(): void {}
}
