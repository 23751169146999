import { Injectable } from '@angular/core';
import { LoggerService } from '../../../modules/shared/services/logger.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { Select, Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { LoginMethodType } from '../state/auth.model';
import { AuthState } from '../state/auth.state';
import { catchError, concatMap, filter, map, take, tap } from 'rxjs/operators';
import { IsAuthenticated } from './isAuthenticated';

@Injectable({
    providedIn: 'root',
})
export class OpenIdAuthenticatedService implements IsAuthenticated {
    @Select(AuthState.openIdInitialized) private oidcInitialized$: Observable<boolean>;
    @Select(AuthState.activeMethod) private activeMethod$: Observable<LoginMethodType>;

    constructor(private logger: LoggerService, private oauthService: OAuthService, private store: Store) {}

    public csIsAuthenticated$() {
        // const isActiveMethodOidc$ = this.activeMethod$.pipe(map((m) => m === 'oidc'));
        return this.oidcInitialized$.pipe(
            filter((i) => i),
            concatMap(() => {
                if (this.oauthService.hasValidIdToken()) {
                    return of(true);
                }
                return of(false);
            }),
            map((b) => !!b),
            catchError((err) => {
                this.logger.error(err);
                return of(false);
            }),
            take(1)
        );
    }
}
