import {
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    OnDestroy,
    OnInit,
    Optional,
    Output,
    Self,
    ViewChild,
} from '@angular/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { ControlValueAccessor, UntypedFormBuilder, NgControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { LoggerService } from '../../../../modules/shared/services/logger.service';
import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

@Component({
    selector: 'dash-gis-phone-input',
    templateUrl: './phone-input.component.html',
    styleUrls: ['./phone-input.component.scss'],
    providers: [
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        { provide: MatFormFieldControl, useExisting: PhoneInputComponent },
    ],
})
export class PhoneInputComponent implements MatFormFieldControl<string>, ControlValueAccessor, OnInit, OnDestroy {
    static nextId = 0;

    displayValue: string;

    onChangeInternal: any;
    onTouchInternal: any;

    @Input() mask = '(___) ___-____';
    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('aria-describedby') userAriaDescribedBy: string;

    @Output() valueChange = new EventEmitter();

    // eslint-disable-next-line rxjs/finnish
    readonly stateChanges = new Subject<void>();
    readonly controlType = 'dash-gis-phone-input';
    readonly autofilled = false;
    @HostBinding() readonly id = `dash-gis-phone-input-${PhoneInputComponent.nextId++}`;

    private focusedInternal: boolean;
    private valueInternal: string | null;
    private placeholderInternal: string;
    private requiredInternal: boolean;
    private disabledInternal: boolean;

    @ViewChild('internalControl', { static: false })
    private internalControl: ElementRef<HTMLInputElement>;

    constructor(
        private logger: LoggerService,
        private fm: FocusMonitor,
        private fb: UntypedFormBuilder,
        private el: ElementRef<HTMLElement>,
        @Optional() @Self() public ngControl: NgControl
    ) {
        if (this.ngControl !== null) {
            this.ngControl.valueAccessor = this;
        }
        fm.monitor(el.nativeElement, true).subscribe((origin) => {
            this.focusedInternal = !!origin;
            this.stateChanges.next();
        });
    }

    // region ControlValueAccessor

    writeValue(obj: any): void {
        this.value = obj;
    }

    registerOnChange(fn: any): void {
        this.onChangeInternal = (value: any) => fn(value);
    }

    registerOnTouched(fn: any): void {
        this.onTouchInternal = fn;
    }

    setDisabledState(isDisabled: boolean): void {}

    // endregion

    ngOnInit() {}

    ngOnDestroy() {
        this.stateChanges.complete();
        this.fm.stopMonitoring(this.el.nativeElement);
    }

    setDescribedByIds(ids: string[]) {
        this.el.nativeElement.setAttribute('aria-describedby', ids.join(' '));
    }

    onContainerClick(event: MouseEvent): void {
        if ((event.target as Element).tagName.toLowerCase() !== 'input') {
            this.internalControl.nativeElement.focus();
        }
    }

    onInput(event: Event) {
        event.preventDefault();
        const value = (event.target as HTMLInputElement).value;
        this.value = value;
        this.onChangeInternal(value);
    }

    private prettifyPhoneNumber(phone: string): string {
        if (!phone) {
            return this.mask;
        }
        // get phone digits only
        const cleaned = phone.replace(/\D*/g, '');

        let out = '';
        let i = 0,
            j = 0;
        while (i < cleaned.length || j < this.mask.length) {
            if (this.mask[j] === '_' && i < cleaned.length) {
                out += cleaned[i++];
                j++;
            } else {
                out += this.mask[j++];
            }
        }
        return out;
    }

    @Input()
    get value(): string | null {
        return this.valueInternal;
    }
    set value(val) {
        this.valueInternal = val;
        this.displayValue = this.prettifyPhoneNumber(val);
        this.stateChanges.next();
    }

    @Input()
    get placeholder(): string {
        return this.placeholderInternal;
    }
    set placeholder(v) {
        this.placeholderInternal = v;
    }

    @Input()
    get required(): boolean {
        return this.requiredInternal;
    }
    set required(v) {
        this.requiredInternal = coerceBooleanProperty(v);
        this.stateChanges.next();
    }

    @Input()
    get disabled(): boolean {
        return this.disabledInternal;
    }
    set disabled(val: boolean) {
        this.disabledInternal = val;
        this.stateChanges.next();
    }

    get focused(): boolean {
        return this.focusedInternal;
    }

    get empty(): boolean {
        return !this.value;
    }

    get errorState() {
        return this.ngControl.errors && Object.keys(this.ngControl.errors).length > 0;
    }

    @HostBinding('class.floating')
    get shouldLabelFloat(): boolean {
        return this.focused || !this.empty;
    }
}
