import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { MarkupBottomSheetComponent } from './bottom-sheet/markup-bottom-sheet.component';
import { MarkupDialogComponent } from './dialog/markup-dialog.component';
import { MarkupTextDialogComponent } from './dialog/markup-text-dialog.component';
import { MccColorPickerModule } from 'material-community-components/color-picker';
import { MARKUP_COMMAND } from './markup.token';
import { markupFactory } from './markup.factory';
import { UiModule } from '../../../../core/ui/ui.module';

@NgModule({
    declarations: [
        MarkupDialogComponent,
        MarkupBottomSheetComponent,
        MarkupTextDialogComponent
    ],
    imports: [
        SharedModule,
        UiModule,
        MccColorPickerModule.forRoot({
            empty_color: 'transparent'
        })
    ],
    providers: [
        { provide: MARKUP_COMMAND, useFactory: markupFactory }
    ]
})
export class MarkupModule {}
